// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-gallery-template-jsx": () => import("./../src/templates/GalleryTemplate.jsx" /* webpackChunkName: "component---src-templates-gallery-template-jsx" */),
  "component---src-pages-index-jsx": () => import("./../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-my-files-jsx": () => import("./../src/pages/my-files.jsx" /* webpackChunkName: "component---src-pages-my-files-jsx" */)
}

